import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
//import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Services from './components/pages/Services';
// import Products from './components/pages/Products';
// import SignUp from './components/pages/SignUp';
// import MaterialTable from './components/MaterialTable';
// import ExampleTable from './components/ExampleTable';
// import FilterTable from './components/FilterTable';
import HandsonTable from './components/HandsonTable';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={HandsonTable} />
          {/* <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/material-table' component={MaterialTable} />
          <Route path='/example-table' component={ExampleTable} />
          <Route path='/filter-table' component={FilterTable} />   */}
          <Route path='/handsontable' component={HandsonTable} />  
        </Switch>
      </Router>
    </>
  );
}

export default App;
